import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Homepage from './components/Homepage';

function App() {
	return (
		<div className="appContainer">
			<Navigation />
			<Homepage />
			<Footer />
		</div>
	);
}

export default App;
