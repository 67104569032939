import React from 'react';

export default function AboutDFS() {
	return (
		<div className="aboutDFS" id="about">
			<h2>About the studio</h2>
			<div className="aboutBoxes">
				<div className="aBox">
					No more pigeonholing, blacklisting, or gatekeeping. Decentralized Film
					Studio will offer the first NFT to fund film projects chosen by you,
					the holders. Every holder will have the option to vote, nominate,
					recommend, and build upon a community that is all about bringing
					worthy art to the big screen. DFS is creating a community of next gen
					industry leaders that will self-fund projects and collaborate in the
					industry to bring in a new way of deciding what entertains us.
				</div>
				<div className="aBox gradientColor">
					With a governance DAO that gives the decision back to its holders we
					have a wider set of decision makers that will bring forth the best
					that the community has to offer. DFS will help with funding,
					marketing, networking, premiers/screenings, online streaming service,
					and even a metaverse theater.{' '}
				</div>
				<div className="aBox">
					With the proceeds of our NFT sales we will be giving back to the
					projects that need that extra push to “make it” in the industry.
					However, the NFT holders will vote to see who is worthy of these
					grants.
				</div>
			</div>
		</div>
	);
}
