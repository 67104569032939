import React from 'react';

export default function Faq() {
	return (
		<div className="faq" id="faq">
			<h2>FAQ</h2>
			<ul>
				<li>What will the mint price be?</li>
				<ul>
					<li>Mint price TBD</li>
				</ul>
				<li>What’s the total number available to mint?</li>
				<ul>
					<li>
						Total number to be minted is 10,000. 200 will be reserved for
						marketing pre and post mint.
					</li>
				</ul>
				<li>What’s the mint date?</li>
				<ul>
					<li>Mint date TBD</li>
				</ul>
				<li>Who is eligible for pre-sale?</li>
				<ul>
					<li>
						Presale will be available to the presale. Presale will total 2000
						and will be allowed to mint 3 max.
					</li>
				</ul>
				<li>When is the reveal date?</li>
				<ul>
					<li>Reveal will be 48 hrs post mint date</li>
				</ul>
				<li>How many DFS NFTs can I mint?</li>
				<ul>
					<li>
						During presale you will be allowed to mint 3. During the general
						sale you can mint 8 total.
					</li>
				</ul>
				<li>How will Projects be selected?</li>
				<ul>
					<li>
						The DAO will vote on a team of volunteers, from the community, to
						vet and offer up projects for voting. Projects will apply with a
						business plan and current standing of their project. Projects
						selected by our volunteers will then be voted on by the DAO.
					</li>
				</ul>
				<li>DAO Grant amounts?</li>
				<ul>
					<li>
						Starting grant amount will be $50K. If no project is selected or
						more grant money is needed for a specific project the DAO will vote
						on how much each project proposal will receive and when.{' '}
					</li>
				</ul>
				<li>How will the grants be funded?</li>
				<ul>
					<li>
						The grants will come from the initial sales of the NFT.
						Approximately $50k will be set aside for two years to fund projects.
						<br /> Remaining profits will go to the purchase of the land in the
						metaverse as well as the development of the Metaverse Decentralized
						Theater.
						<br />
						Resales, merch, and metaverse theater profits will create the
						sustainability needed for DFS to continue its grant process after
						the initial two years funding.{' '}
					</li>
				</ul>
				<li>Do the projects have to pay back the grant?</li>
				<ul>
					<li>
						No, but yes. DFS will not require payment for grant amounts.
						However, if the project is profitable through our community's effort
						to market, advertise, and sell the project then we will highly
						encourage the profitable project to give back to the community. Each
						project will be vetted and will have contracts drawn up before
						grants are distributed. Every project will have a different
						agreement depending on their stage of development.
					</li>
				</ul>
				<li>
					I have a project I want to share but I don’t own an NFT, can I still
					participate?
				</li>
				<ul>
					<li>
						NFT holders will have the advantage to share and submit their
						personal projects. However, all projects will be accepted for
						review. Our vetting team will review each project proposal and will
						pass along their selections for the community to vote on.{' '}
					</li>
				</ul>
			</ul>
		</div>
	);
}
