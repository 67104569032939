import React from 'react';

export default function RoadMap() {
	return (
		<div className="roadmap" id="roadmap">
			<h2>Roadmap</h2>
			<h3 className="gradientColor">Premint:</h3>
			<ul>
				<li>Build community</li>
				<li>Raffle off WL spots </li>
				<li>Reserve 200 Images for Marketing/Giveaways</li>
			</ul>
			<h3 className="gradientColor">ROADMAP 1.0:</h3>
			<div className="percentages">
				<div className="numberP">25%</div>
				<ul className="listDesc">
					<li>
						5 NFT’s will be raffled off and airdropped for 25% first holders.
					</li>
					<li>Voting for the DAO will be set up.</li>
				</ul>
			</div>
			<div className="percentages">
				<div className="numberP">50%</div>
				<ul className="listDesc">
					<li>Start receiving applications for first projects.</li>
					<li>Voting for the first grant to be established and funded.</li>
					<li>
						10 NFT’s will be raffled off and airdropped for 50% first holders.
					</li>
					<li>Merch Store will launch.</li>
				</ul>
			</div>
			<div className="percentages">
				<div className="numberP">75%</div>
				<ul className="listDesc">
					<li>
						15 NFT’s will be raffled off and airdropped for the first 75% of
						holders.
					</li>
					<li>Merch Store Discount to exclusive products.</li>
					<li>Purchase of Land in the Metaverse</li>
				</ul>
			</div>
			<div className="percentages">
				<div className="numberP">100%</div>
				<ul className="listDesc">
					<li>Exclusive merch giveaway raffle for holders.</li>
					<li>DFS NFT resales shared with DAO Grant and community wallet.</li>
					<li>Creation of the first ever Metaverse Movie Theater</li>
					<li>
						Movie ticket profits of the Metaverse Movie Theater sent to DAO
						Grant and community wallet
					</li>
					<li>Token lunch for genesis NFT holders founded by DFS profits.</li>
				</ul>
			</div>
			<div className="percentages">
				<ul className="listDesc">
					<h4>Future proposals for DFS would include:</h4>
					<li>Online streaming service</li>
					<li>Future genre specific NFTs</li>
					<li>Film Festival Hosting</li>
					<li>IRL meet ups for project development</li>
				</ul>
			</div>
		</div>
	);
}
