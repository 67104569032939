import React from 'react';

import bakg from '../assets/background.jpg';
import RoadMap from './RoadMap';
import TheTeam from './TheTeam';
import Faq from './Faq';
import nft from '../assets/NFT_30.png';
import AboutDFS from './AboutDFS';
import Membership from './Membership';
import SneakPeek from './SneakPeek';

export default function Homepage() {
	const bkg = `url(${bakg})`;
	return (
		<div className="homePage">
			<div style={{ backgroundImage: bkg }} className="mainContainer">
				<div className="titleBox">
					<div className="greating1"></div>
					<div className="greating">
						<h1>Decentralized Cinema for Everyone</h1>
						<p>
							With the proceeds of our NFT sales we will be giving back to the
							projects that need that extra push to “make it” in the industry.
							However, the NFT holders will vote to see who is worthy of these
							grants.
						</p>
					</div>
				</div>
				<div className="bottomgradient"></div>
			</div>
			<div className="contentContainer">
				<div className="welcomeBox">
					<div className="cBox">
						<p>
							Decentralized Film Studio NFT (DFS) is the decentralized path to
							decentralized filmmaking. For years, great talents of the seventh
							art have been submitting their cinematographic works for the
							consideration of large companies that decide on the future of
							their careers and the future of their productions.
						</p>
						<p>Enough of that!</p>
					</div>
					<div className="cBox">
						<img src={nft} alt="DFS NFT art" />
					</div>
				</div>
			</div>
			<Membership />
			<SneakPeek />
			<AboutDFS />
			<RoadMap />
			<TheTeam />
			<Faq />
		</div>
	);
}
