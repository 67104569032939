import React from 'react';

import spOne from '../assets/NFT_17.png';
import spTwo from '../assets/NFT_20.png';
import spThree from '../assets/NFT_27.png';
import spFour from '../assets/NFT-art.png';

export default function SneakPeek() {
	return (
		<div className="spSection" id="sp">
			<h2>Sneak Peek</h2>
			<div className="team">
				<figure>
					<img src={spOne} alt="Julian Moreno" />
				</figure>
				<figure>
					<img src={spTwo} alt="Edward Moreno" />
				</figure>
				{/* <figure>
					<img src={dianaM} alt="Diana - Tired Moms founder" />

					<figcaption>
						Diana Moreno
						<br />
						<strong>MARKETING</strong>
					</figcaption>
				</figure> */}

				<figure>
					<img src={spThree} alt="Giselle Escandon" />
				</figure>
				<figure>
					<img src={spFour} alt="Angela Aristizabal" />
				</figure>
			</div>
		</div>
	);
}
