import React from 'react';
import Links from './Links';
import dfsLogo from '../assets/DFS-logo.png';

export default function Footer() {
	return (
		<div className="footer">
			<div className="logo">
				<img src={dfsLogo} alt="DFS NFT Logo" />
				<Links />
			</div>
		</div>
	);
}
