import React from 'react';

import dianaM from '../assets/Diana.png';
import giselleE from '../assets/Giselle.png';
import angelaA from '../assets/Angela.png';
import edwardM from '../assets/Edward.jpg';
import lucasD from '../assets/lucas.jpg';
import julianM from '../assets/Julian.jpg';

export default function TheTeam() {
	return (
		<div className="teamSection" id="team">
			<h2>Our team</h2>
			<div className="team">
				<figure>
					<img src={julianM} alt="Julian Moreno" />

					<figcaption>
						Julian Moreno
						<br />
						<strong>FOUNDER/DEVELOPER</strong>
					</figcaption>
				</figure>
				<figure>
					<img src={edwardM} alt="Edward Moreno" />

					<figcaption>
						Edward Moreno
						<br />
						<strong>FOUNDER</strong>
					</figcaption>
				</figure>
				{/* <figure>
					<img src={dianaM} alt="Diana - Tired Moms founder" />

					<figcaption>
						Diana Moreno
						<br />
						<strong>MARKETING</strong>
					</figcaption>
				</figure> */}

				<figure>
					<img src={giselleE} alt="Giselle Escandon" />

					<figcaption>
						Giselle Escandon
						<br />
						<strong>ARTIST</strong>
					</figcaption>
				</figure>
				<figure>
					<img src={angelaA} alt="Angela Aristizabal" />

					<figcaption>
						Angela Aristizabal
						<br />
						<strong>UX/UI</strong>
					</figcaption>
				</figure>

				<figure>
					<img src={lucasD} alt="Lucas Decleva" />

					<figcaption>
						Lucas Decleva
						<br />
						<strong>DEVELOPER</strong>
					</figcaption>
				</figure>
			</div>
		</div>
	);
}
