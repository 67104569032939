import React, { useState } from 'react';

export default function Membership() {
	const [selection, setSelection] = useState(1);

	return (
		<div className="membership" id="membership">
			<h2>Membership Benefits</h2>

			<div className="benefitsContainer">
				<ul className="buttons">
					<li
						onClick={() => setSelection(1)}
						className={selection === 1 ? 'active' : ''}
					>
						Community
					</li>
					<li
						onClick={() => setSelection(2)}
						className={selection === 2 ? 'active' : ''}
					>
						DAO Governance
					</li>
					<li
						onClick={() => setSelection(3)}
						className={selection === 3 ? 'active' : ''}
					>
						Projects
					</li>
					<li
						onClick={() => setSelection(4)}
						className={selection === 4 ? 'active' : ''}
					>
						Commercial Rights
					</li>
					<li
						onClick={() => setSelection(5)}
						className={selection === 5 ? 'active' : ''}
					>
						Exclusive Merchandise
					</li>
					<li
						onClick={() => setSelection(6)}
						className={selection !== 6 ? '' : 'active'}
					>
						NFT Holders
					</li>
				</ul>
				<div className="description">
					{selection === 1 ? (
						<p>
							Participate in a community that helps grow and collaborate in the
							creation of new up and coming ventures.
						</p>
					) : selection === 2 ? (
						<p>1 NFT=1 vote in DAO Governance</p>
					) : selection === 3 ? (
						<p>Project participation fast track.</p>
					) : selection === 4 ? (
						<p>
							Commercial Rights The NFTs you purchase and hold will be yours to
							brag about. Our smart contract ensures you are the owner.
						</p>
					) : selection === 5 ? (
						<p>Exclusive Merchandise</p>
					) : (
						<p>
							NFT holders will receive exclusive community perks in the
							metaverse and IRL.
						</p>
					)}

					<p></p>
				</div>
			</div>
		</div>
	);
}
